import React, { useState } from 'react';
import logo from './logo.jpg';
import './App.css';

function App() {
  const states = [
    { link: "https://form.jotform.com/203015867288158", name: "Arizona"},
    { link: "https://form.jotform.com/202995647990170", name: "California"},
    { link: "https://form.jotform.com/202828383788167", name: "Colorado"},
    { link: "https://form.jotform.com/210067494290152", name: "Connecticut"},
    { link: "https://form.jotform.com/203016241490140", name: "Delaware"},
    { link: "https://form.jotform.com/210067624290147", name: "District of Columbia"},
    { link: "https://form.jotform.com/202808023990151", name: "Florida"},
    { link: "https://form.jotform.com/202927907388165", name: "Georgia"},
    { link: "https://form.jotform.com/202828269490160", name: "Idaho"},
    { link: "https://form.jotform.com/210067148688159", name: "Illinois"},
    { link: "https://form.jotform.com/210067489190155", name: "Indiana"},
    { link: "https://form.jotform.com/202827892588169", name: "Kentucky"},
    { link: "https://form.jotform.com/203015933988159", name: "Louisiana"},
    { link: "https://form.jotform.com/210067375388158", name: "Maryland"},
    { link: "https://form.jotform.com/210067922690152", name: "Massachusetts"},
    { link: "https://form.jotform.com/210067925788163", name: "Michigan"},
    { link: "https://form.jotform.com/210067856790159", name: "Missouri"},
    { link: "https://form.jotform.com/210068051090140", name: "New Jersey"},
    { link: "https://form.jotform.com/210056795890160", name: "New York"},
    { link: "https://form.jotform.com/210067338888162", name: "Ohio"},
    { link: "https://form.jotform.com/203016058790149", name: "Oklahoma"},
    { link: "https://form.jotform.com/210067850790153", name: "Pennsylvania"},
    { link: "https://form.jotform.com/210067524388154", name: "Rhode Island"},
    { link: "https://form.jotform.com/202927667088165", name: "South Carolina"},
    { link: "https://form.jotform.com/202918034990155", name: "Texas"},
    { link: "https://form.jotform.com/210068026890150", name: "Virginia"},
    { link: "https://form.jotform.com/210067328490150", name: "Wisconsin"}
  ];
  const [showList, setShowList] = useState(false);

  return (
    <div className="App">
      <header>
        <img src={logo} alt="logo" width={256}/>
      </header>
      <content>
        <h1>
          Get your <br/>
          free medicare consultation <br/>below
        </h1>
        <div className="select-wrapper">
          { showList &&
            <ul>
              { states.map((state) =>
                <li onClick={() => window.location = state.link}>{state.name}</li>
              )}
            </ul>
          }
          { !showList &&
            <button type="button" onClick={() => setShowList(!showList)}>
              Select Your State
            </button>
          }
        </div>
      </content>
      <div className="sub-footer-outer">
        <div className="sub-footer">
          <h3>
            CONTACT US
          </h3>
          <ul>
            <li> <a href="tel:8669948789">For Questions on Medicare Options: 866-994-8789</a></li>
            <li> <a href="tel:8556220582">For Our Care Partners: 855-622-0582</a></li>
            <li> <a href="tel:8449463602">Client Support: 844-946-3602</a></li>
          </ul>
        </div>
      </div>
      <footer>
        <p>
          We specialize in Medicare Advantage, Part D, and Medicare Supplements.
          <br/><br/>
          Find out today why Baby Boomers love our company which offers free consultations!
        </p>
      </footer>
    </div>
  );
}

export default App;
